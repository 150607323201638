<template>
    <div class="home">
        <div class="vm-poster">
            <a id="home" name="home"></a>
            <div class="cover">
                <h1><em>行业先锋，</em>专业服务</h1>
                <p>·SMT贴片/DIP插件加工</p>
                <p>·组装、老化测试、包装</p>
            </div>
        </div>

        <!-- 核心业务 -->
        <a id="business" name="business" class="anchor"></a>
        <section class="vm-section">
            <h1 class="vm-title">核心业务</h1>
            <h2 class="vm-subtitle">坚持捍卫民族电工精工品质</h2>
            <div class="vm-group">
                <div class="vm-group-row">
                    <div class="vm-group-item picture align-right">
                        <div class="img-box prod">
                            <img src="../assets/img/business/business01.jpeg" alt="SMT/DIP">
                        </div>
                    </div>
                    <div class="vm-group-item vm-card">
                        <div class="container">
                            <h3>SMT贴片/DIP插件</h3>
                            <div class="p-label"></div>
                            <p>微讯承接SMT贴片、DIP插件加工，含小批量试制、批量产品的生产。公司根据客户和产品的特性制定了不同的生产制程，高效便捷、快速交货。</p>
                        </div>
                    </div>
                </div>
                <div class="vm-group-row">
                    <div class="vm-group-item picture">
                        <div class="img-box prod">
                            <img src="../assets/img/business/business02.jpeg" alt="产品组装">
                        </div>
                    </div>
                    <div class="vm-group-item vm-card align-right">
                        <div class="container">
                            <h3>产品组装</h3>
                            <div class="p-label"></div>
                            <p>微讯具有组装测试生产线，具备多种电子产品组装测试经验和能力。可以根据产品特性和客户要求来制定老化方案。提供一站式打包装和塑封服务。</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- 产品展示 -->
        <a id="production" name="production" class="anchor"></a>
        <section class="vm-section">
            <h1 class="vm-title">产品展示</h1>
            <h2 class="vm-subtitle">千万次重复，品质始终如一</h2>

            <div class="vm-prod-header vm-prod-header-first">
                <p class="h-title">SMT贴片工艺</p>
                <div class="vm-prod-pc">
                    <production-card label="PCBA产品1">
                        <img src="../assets/img/production/production01.png" alt="PCBA产品1">
                    </production-card>
                    <production-card label="PCBA产品2">
                        <img src="../assets/img/production/production02.png" alt="PCBA产品2">
                    </production-card>
                    <production-card label="PCBA产品3">
                        <img src="../assets/img/production/production03.png" alt="PCBA产品3">
                    </production-card>
                    <production-card label="PCBA产品4">
                        <img src="../assets/img/production/production04.png" alt="PCBA产品4">
                    </production-card>
                    <production-card label="PCBA产品5">
                        <img src="../assets/img/production/production05.png" alt="PCBA产品5">
                    </production-card>
                    <production-card label="PCBA产品6">
                        <img src="../assets/img/production/production06.png" alt="PCBA产品6">
                    </production-card>
                    <production-card label="PCBA产品7">
                        <img src="../assets/img/production/production07.png" alt="PCBA产品7">
                    </production-card>
                    <production-card label="PCBA产品8">
                        <img src="../assets/img/production/production08.png" alt="PCBA产品8">
                    </production-card>
                </div>
                <div class="vm-prod-mobile">
                    <v-carousel
                        cycle
                        height="auto"
                        interval="4000"
                        hide-delimiters
                        show-arrows-on-hover>
                        <v-carousel-item>
                            <v-sheet color="#F6F6F6" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #F6F6F6;">
                                            <img src="../assets/img/production/production01.png" alt="PCBA产品1">
                                        </div>
                                        <p class="carousel-text">PCBA产品1</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#F6F6F6" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #F6F6F6;">
                                            <img src="../assets/img/production/production02.png" alt="PCBA产品2">
                                        </div>
                                        <p class="carousel-text">PCBA产品2</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#F6F6F6" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #F6F6F6;">
                                            <img src="../assets/img/production/production03.png" alt="PCBA产品3">
                                        </div>
                                        <p class="carousel-text">PCBA产品3</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#F6F6F6" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #F6F6F6;">
                                            <img src="../assets/img/production/production04.png" alt="PCBA产品4">
                                        </div>
                                        <p class="carousel-text">PCBA产品4</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#F6F6F6" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #F6F6F6;">
                                            <img src="../assets/img/production/production05.png" alt="PCBA产品5">
                                        </div>
                                        <p class="carousel-text">PCBA产品5</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#F6F6F6" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #F6F6F6;">
                                            <img src="../assets/img/production/production06.png" alt="PCBA产品6">
                                        </div>
                                        <p class="carousel-text">PCBA产品6</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#F6F6F6" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #F6F6F6;">
                                            <img src="../assets/img/production/production07.png" alt="PCBA产品7">
                                        </div>
                                        <p class="carousel-text">PCBA产品7</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#F6F6F6" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #F6F6F6;">
                                            <img src="../assets/img/production/production08.png" alt="PCBA产品8">
                                        </div>
                                        <p class="carousel-text">PCBA产品8</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
                </div>
            </div>

            <div class="vm-prod-header vm-prod-header-second">
                <p class="h-title">产品效果展示</p>
                <div class="vm-prod-pc">
                    <production-card label="投影产品案例1">
                        <img src="../assets/img/production/production09.png" alt="投影产品案例1">
                    </production-card>
                    <production-card label="投影产品案例2">
                        <img src="../assets/img/production/production10.png" alt="投影产品案例2">
                    </production-card>
                    <production-card label="机顶盒产品案例1">
                        <img src="../assets/img/production/production11.png" alt="机顶盒产品案例1">
                    </production-card>
                    <production-card label="机顶盒产品案例2">
                        <img src="../assets/img/production/production12.png" alt="机顶盒产品案例2">
                    </production-card>
                    <production-card label="翻译机产品案例1">
                        <img src="../assets/img/production/production13.png" alt="翻译机产品案例1">
                    </production-card>
                    <production-card label="翻译机产品案例2">
                        <img src="../assets/img/production/production14.png" alt="翻译机产品案例2">
                    </production-card>
                    <production-card label="遥控器产品案例">
                        <img src="../assets/img/production/production15.png" alt="遥控器产品案例">
                    </production-card>
                    <production-card label="语音鼠标产品案例">
                        <img src="../assets/img/production/production16.png" alt="语音鼠标产品案例">
                    </production-card>
                </div>
                <div class="vm-prod-mobile">
                    <v-carousel
                        cycle
                        height="auto"
                        interval="4000"
                        hide-delimiters
                        show-arrows-on-hover>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/production/production09.png" alt="投影产品案例1">
                                        </div>
                                        <p class="carousel-text">投影产品案例1</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/production/production10.png" alt="投影产品案例2">
                                        </div>
                                        <p class="carousel-text">投影产品案例2</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/production/production11.png" alt="机顶盒产品案例1">
                                        </div>
                                        <p class="carousel-text">机顶盒产品案例1</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/production/production12.png" alt="机顶盒产品案例2">
                                        </div>
                                        <p class="carousel-text">机顶盒产品案例2</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/production/production13.png" alt="翻译机产品案例1">
                                        </div>
                                        <p class="carousel-text">翻译机产品案例1</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/production/production14.png" alt="翻译机产品案例2">
                                        </div>
                                        <p class="carousel-text">翻译机产品案例2</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/production/production15.png" alt="遥控器产品案例">
                                        </div>
                                        <p class="carousel-text">遥控器产品案例</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="img-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/production/production16.png" alt="语音鼠标产品案例">
                                        </div>
                                        <p class="carousel-text">语音鼠标产品案例</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
                </div>
            </div>
        </section>

        <!-- poster -->
        <a id="about" name="about" class="anchor"></a>
        <div class="poster-middle-bar">
            <v-img max-height="404" src="../assets/img/poster/poster02.png"></v-img>
        </div>
        <!-- 关于我们 -->
        <section class="vm-section">
            <h1 class="vm-title">关于我们</h1>
            <p class="vm-detail">微讯智造（广州）电子有限公司，成立于2017年，为广州市讯码通讯科技有限公司全资子公司。公司依托承接母公司讯码科技设计的3C数码产品外包制造订单作为起点，不断开发完善智能制造配套系统和体系，在此基础上扩大对外的制造业务订单，提升系统的市场适应性。</p>
            <div class="show-about">
                <p class="vm-detail">现有产品线包括智能投影仪、网络机顶盒、语音学习机、智能翻译机、同屏器、智能体感摄像头等智能硬件。为CCTV未来电视NewTV、爱奇艺、腾讯、冠捷AOC、大麦、Nayatel（巴）、Natco（越）、EZECOM（柬）、Digital Sky（柬）等国内外知名品牌商和运营商提供OEM/ODM服务。</p>
                <p class="vm-detail">坐落于广州市番禺区石楼镇80号之一一栋，厂房面积约5200平方。公司具备较强的电子元器件采购及综合配套能力，在物料质量和成本控制上有明显优势。厂区配备：SMT车间、老化房、组装测试车间、包装车间、硬件实验室等全制程工艺设施，足以应对任何形态的生产需求。</p>
                <p class="vm-detail">服务宗旨：为客户提供最专业的服务,最优质的产品,最迅速的交期，最有竞争力的价格。我们希望用微讯的精益求精和客户至上带来合作的共赢，我们更希望用微讯的科学管理和持续改进来见证客户的腾飞!</p>
            </div>
        </section>

        <!-- 移动端分割线 -->
        <div v-show="isShowAbout" class="mobile-hr"></div>

        <!-- 关于我们-扩展部分 -->
        <section v-show="isShowAbout" class="vm-section">
            <h1 class="vm-title">公司资质</h1>
            <p class="vm-detail">微讯智造拥有专业的核心技术及管理团队，推行精益生产模式，全员执行目视化、6S管理，以“消除浪费、持续改善”为理念，提高产品质量，缩短产品生产时间等持续减少生产成本投入，最终实现客户利益最优化。</p>
            <div class="show-about">
                <p class="vm-detail">微讯通过了ISO9001:2015、ISO14001:2015和ISO45001:2018三大体系认证，全面实施了“TPS精益制造体系”和“6S 管理体系”，以及SGS Factory Audit认证。</p>
            </div>
            <div class="vm-redentials">
                <Credentials label="ISO9001证书">
                    <img src="../assets/img/credentials/credentials01.svg" alt="微讯智造ISO9001证书">
                </Credentials>
                <Credentials label="ISO14001证书">
                    <img src="../assets/img/credentials/credentials02.svg" alt="微讯智造ISO14001证书">
                </Credentials>
                <Credentials label="ISO45001证书">
                    <img src="../assets/img/credentials/credentials03.svg" alt="微讯智造ISO45001证书">
                </Credentials>
            </div>
        </section>

         <div v-show="isShowAbout" class="mobile-hr"></div>

        <!-- 公司环境 -->
        <section v-show="isShowAbout" class="vm-section">
            <h1 class="vm-title">公司环境</h1>
            <p class="vm-detail">公司配备2条高速贴片生产线，2台GKG全自动印刷机，7台JUKI-2000系列贴片机，2台劲拓十温区回流焊，2台神州视觉7120AOI，防潮柜，烤箱，IC自动烧录炉机，首件测试仪，温测试仪等一系列设备。</p>
            <div class="show-about">
                <p class="vm-detail">具有高精度元器件贴片加工能力，可以贴装0.3mm的BGA芯片及0201小型器件，可以贴装高精度的FPC软板，出厂合格率达到99%以上。</p>
            </div>
            <div class="vm-env">
                <div class="vm-prod-pc">
                    <production-card label="10温区回流焊">
                        <img src="../assets/img/factory/env01.png" alt="10温区回流焊">
                    </production-card>
                    <production-card label="SMT生产线（总览）">
                        <img src="../assets/img/factory/env02.png" alt="SMT生产线（总览）">
                    </production-card>
                    <production-card label="SMT生产1线">
                        <img src="../assets/img/factory/env03.png" alt="SMT生产1线">
                    </production-card>
                    <production-card label="SMT生产2线">
                        <img src="../assets/img/factory/env04.png" alt="SMT生产2线">
                    </production-card>
                    <production-card label="AOI测试">
                        <img src="../assets/img/factory/env05.png" alt="AOI测试">
                    </production-card>
                    <production-card label="产品烧录">
                        <img src="../assets/img/factory/env06.png" alt="产品烧录">
                    </production-card>
                    <production-card label="投影组装线">
                        <img src="../assets/img/factory/env07.png" alt="投影组装线">
                    </production-card>
                    <production-card label="仓库间">
                        <img src="../assets/img/factory/env08.png" alt="仓库间">
                    </production-card>
                </div>
                <div class="vm-prod-mobile">
                    <v-carousel cycle height="auto" interval="4000" hide-delimiters show-arrows-on-hover>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="env-container" align="center" justify="center">
                                    <div>
                                        <div class="env-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/factory/env01.png" alt="10温区回流焊">
                                        </div>
                                        <p class="carousel-text">10温区回流焊</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="env-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/factory/env02.png" alt="SMT生产线（总览）">
                                        </div>
                                        <p class="carousel-text">SMT生产线（总览）</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="env-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/factory/env03.png" alt="SMT生产1线">
                                        </div>
                                        <p class="carousel-text">SMT生产1线</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="env-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/factory/env04.png" alt="SMT生产2线">
                                        </div>
                                        <p class="carousel-text">SMT生产2线</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="env-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/factory/env05.png" alt="AOI测试">
                                        </div>
                                        <p class="carousel-text">AOI测试</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="env-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/factory/env06.png" alt="产品烧录">
                                        </div>
                                        <p class="carousel-text">产品烧录</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="env-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/factory/env07.png" alt="投影组装线">
                                        </div>
                                        <p class="carousel-text">投影组装线</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet color="#FFFFFF" height="100%">
                                <v-row class="fill-height" align="center" justify="center">
                                    <div>
                                        <div class="env-box" style="background-color: #FFFFFF;">
                                            <img src="../assets/img/factory/env08.png" alt="仓库间">
                                        </div>
                                        <p class="carousel-text">仓库间</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
                </div>
            </div>
        </section>

        <!-- <div v-show="isShowAbout"  class="toggle toggle-bottom">
            <div class="toggle-btn" @click="onClickToggle">
                <img src="../assets/img/arrow_up.svg">
                <p class="toggle-text">收起</p>
            </div>
        </div>

        <div v-show="!isShowAbout" class="toggle">
            <div class="toggle-btn" @click="onClickToggle">
                <img src="../assets/img/arrow_down.svg">
                <p class="toggle-text">查看更多</p>
            </div>
        </div> -->

        <!-- 联系我们 -->
        <a id="connect" name="connect" class="anchor"></a>
        <section class="vm-section">
            <h1 class="vm-title">联系我们</h1>
            <div class="connect">
                <div class="vm-map">
                    <img class="map-pc" src="../assets/img/map/map_pc.svg">
                    <img class="map-mobile" src="../assets/img/map/map_mobile.svg">
                </div>
                <connect-bar></connect-bar>
            </div>
        </section>

        <div class="float-bar">
            <div class="qr-code">
                <div class="detail">
                    <div class="code">
                        <img src="../assets/img/QRCode.png" alt="15915723834">
                    </div>
                    <div class="info-row">
                        <i class="iconfont icon-user2"></i>
                        <span>洪先生</span>
                    </div>
                    <div class="info-row">
                        <i class="iconfont icon-phone2"></i>
                        <span>15915723834</span>
                    </div>
                    <div class="info-row">
                        <i class="iconfont icon-qq2"></i>
                        <span>337004613</span>
                    </div>
                </div>
            </div>
            <div class="to-top" @click="toTop">
                <img src="../assets/img/iconarrow.svg">
            </div>
        </div>
    </div>
</template>

<script>
import ProductionCard from '@/components/ProductionCard.vue'
import Credentials from '@/components/Credentials.vue'
import ConnectBar from '@/components/TheConnectBar.vue'

export default {
    name: 'Home',
    data () {
        return {
            isShowAbout: true
        }
    },
    methods: {
        onClickToggle () {
            // const status = this.isShowAbout
            // this.isShowAbout = !status
        },
        toTop () {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' })
        }
    },
    components: {
        ProductionCard, Credentials, ConnectBar
        // VsoonMap
    }
}
</script>

<style lang="scss">
@import "../assets/scss/vsoontronics.scss";
.home {
    .align-right {
        text-align: right;
        p {
            float: right;
        }
        .p-label {
            margin-right: 4px;
            float: right;
        }
    }
    .anchor {
        padding-left: 12px;
        border-bottom: 1px solid transparent;
        font-size: 12px;
        opacity: 0;
            @media screen and (max-width: 960px) {
                padding-top: 1px;
                margin-top:-1px;
            }
        }
}
</style>
