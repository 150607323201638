<template>
    <v-app>
        <header id="nav">
            <div class="nav-info">
                <div class="nav-info-container">
                    <div class="left">
                        <info-bar icon="icon-position" info="广东省广州市番禺区石楼镇珠江路80号一栋"></info-bar>
                    </div>
                    <div class="right">
                        <div class="info-box">
                            <info-bar type="email" icon="icon-email" info="hongzhibiao@vsoontech.com"></info-bar>
                        </div>
                        <div class="info-line"></div>
                        <div class="info-box">
                            <info-bar type="wechat" icon="icon-wechat" info="15915723834"></info-bar>
                        </div>
                        <div class="info-line"></div>
                        <div class="info-box">
                            <info-bar type="qq" icon="icon-qq" info="337004613"></info-bar>
                        </div>
                    </div>
                </div>
            </div>
            <div class="nav-container">
                <div class="nav-header">
                    <img src="./assets/img/mobile_top.png">
                </div>
                <div class="nav-logo">
                    <div class="logo">
                        <img :src="require('./assets/vsoonmade.svg')" alt="VSOON MADE">
                    </div>
                    <div class="nav-info-mobile">
                        <div class="info-box">
                            <info-button-mobile type="phone" info="15915723834"></info-button-mobile>
                            <!-- <info-bar-mobile icon="icon-phone" info="15915723834"></info-bar-mobile> -->
                        </div>
                        <div class="info-box">
                            <info-button-mobile type="qq" info="337004613"></info-button-mobile>
                            <!-- <info-bar-mobile type="qq" icon="icon-qq" info="337004613"></info-bar-mobile> -->
                        </div>
                    </div>
                </div>

                <div class="nav-list">
                    <ul class="vm-nav">
                        <li
                            class="vm-nav-item"
                            v-for="(nav, index) in navList"
                            :key="index"
                            :class="{ active: curNav === nav.name }"
                            @click="jumpToAnchor(nav.name)">
                            <a class="vm-link">{{ nav.title }}</a>
                        </li>
                    </ul>
                </div>
                <div class="nav-phone">
                    <img class="icon-phone" :src="require('./assets/img/phone.svg')">
                    <span class="phone-number"><a href="tel:15915723834">15915723834</a></span>
                </div>
            </div>
        </header>
        <main id="main" class="vm-main">
            <router-view></router-view>
        </main>
        <footer id="footer">
            <div class="vm-footer">
                <p class="info-pc">
                    微讯智造（广州）电子有限公司 VSOONMAKE ALL Rights Reserved.
                    <em style="font-style:normal;"> | </em>
                    <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备16027162号</a>
                </p>
                <!-- <p>
                    Copyright @ 2021-2025 VSOONMAKE ELECTRONICS CO.,LIMITED
                    <em style="font-style:normal;"> | </em>
                    <i class="icon-government"></i>
                    <a class="link" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011302000683" target="_blank">粤公网安备 44011302000683号</a>
                </p> -->
            </div>
        </footer>
    </v-app>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill'
import InfoBar from '../src/components/InfoBar.vue'
// import InfoBarMobile from '../src/components/InfoBarMobile.vue'
import InfoButtonMobile from '../src/components/InfoButtonMobile.vue'

export default {
    name: 'App',
    data: () => ({
        curNav: 'home',
        navList: [
            { title: '首页', path: '#home', name: 'home' },
            { title: '核心业务', path: '#business', name: 'business' },
            { title: '产品展示', path: '#production', name: 'production' },
            { title: '关于我们', path: '#about', name: 'about' },
            { title: '联系我们', path: '#connect', name: 'connect' }
        ]
    }),
    mounted () {
        smoothscroll.polyfill()
        const wrap = document
        wrap.addEventListener('scroll', (e) => {
            // home
            const domHome = document.getElementById('home')
            const offsetHome = domHome.offsetTop - 80 + 21
            // business
            const domBusiness = document.getElementById('business')
            const offsetBusiness = domBusiness.offsetTop - 80 + 21
            // production
            const domProduction = document.getElementById('production')
            const offsetProduction = domProduction.offsetTop - 90 + 21
            // about
            const domAbout = document.getElementById('about')
            const offsetAbout = domAbout.offsetTop - 80 + 21
            // connect
            const domConnect = document.getElementById('connect')
            const offsetConnect = domConnect.offsetTop - 80 + 21
            // documnet
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop // 滚动条位移
            const scrollHeight = document.documentElement.scrollHeight // 总长度
            const windowInnerHeight = window.innerHeight
            // connet区域添加了6像素的偏移量，未来适配移动端圆角机型
            if (scrollTop >= offsetConnect || scrollHeight - scrollTop - 6 <= windowInnerHeight) {
                this.curNav = 'connect'
            } else if (scrollTop >= offsetAbout) {
                this.curNav = 'about'
            } else if (scrollTop >= offsetProduction) {
                this.curNav = 'production'
            } else if (scrollTop >= offsetBusiness) {
                this.curNav = 'business'
            } else if (scrollTop >= offsetHome) {
                this.curNav = 'home'
            } else {}
        })
    },
    methods: {
        jumpToAnchor (name) {
            const element = document.getElementById(name)
            const offsetTop = element.offsetTop - 80 + 21
            window.scroll({ top: offsetTop, left: 0, behavior: 'smooth' })
        },
        updateNavStyle (name) {
            if (name) {
                this.curNav = name
            }
        }
    },
    components: {
        InfoBar, InfoButtonMobile
        // InfoBarMobile
    }
}
</script>

<style lang="scss">
@import "./assets/scss/app.scss";
</style>
