<template>
    <div class="credentials">
        <div class="preview">
            <slot></slot>
        </div>
        <div class="text">{{ label }}</div>
    </div>
</template>

<script>
export default {
    name: 'ProductionCard',
    props: {
        label: {
            type: String,
            default: '产品名称'
        }
    }
}
</script>

<style lang="scss" scope>
.credentials {
    box-sizing: border-box;
    display: inline-block;
    .preview {
        margin: 0 auto;
        width: 100%;
        height: auto;
        overflow: hidden;
        img {
            width: 100%;
            height: auto;
            border: 1px solid #999999;
        }
    }
    .text {
        width: 100%;
        margin-top: 12px;
        font-size: 8px;
        text-align: center;
        font-weight: 400;
        line-height: 12px;
        color: #666666;
        letter-spacing: 1px;
    }
}
@media screen and (min-width: 960px) {
    .credentials {
        width: 408px;
    }
}

@media screen and (max-width: 960px) {
    .credentials {
        width: 142px;
    }
}
</style>
