<template>
    <div class="info-button-mobile">
        <a v-if="type === 'qq'" :href="'mqqwpa://im/chat?chat_type=wpa&uin=' + info + '&version=1&src_type=web&web_src=oicqzone.com'">
            <img src="../assets/img/mobile/qq.png" alt="">
        </a>
        <a v-else :href="'tel:' + info">
            <img src="../assets/img/mobile/phone.png" alt="">
        </a>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'phone'
        },
        info: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
.info-button-mobile {
    width: 33px;
    height: 33px;
    background-color: transparent;
    a {
        display: block;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
