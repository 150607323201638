<template>
    <div class="connect-bar">
        <h3>微讯智造（广州）电子有限公司</h3>
        <p>联系人：洪先生</p>
        <div class="vs-item">
            <div class="vs-item-label">手机</div>
            <div class="vs-item-container"><a href="tel:15915723834">：15915723834（微信同号）</a></div>
        </div>
        <div class="vs-item">
            <div class="vs-item-label">QQ号</div>
            <div class="vs-item-container">：337004613</div>
        </div>
        <div class="vs-item">
            <div class="vs-item-label">邮箱</div>
            <div class="vs-item-container">：hongzhibiao@vsoontech.com</div>
        </div>
        <div class="vs-item">
            <div class="vs-item-label">地址</div>
            <div class="vs-item-container">：广东省广州市番禺区石楼镇80号一栋</div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.connect-bar {
    h3 {
        font-weight: 500;
        color: #37474F;
        opacity: 1;
    }
    p {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        letter-spacing: 1px;
        opacity: 1;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    // mobile
    @media screen and (max-width: 960px) {
        .vs-item {
            height: 22px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            .vs-item-label {
                width: 45px;
                white-space: normal;
                text-align: justify;
                display: inline-block;
                vertical-align: middle;
                height: 100%;
                &::after {
                    content: "";
                    width: 100%;
                    display: inline-block;
                }
            }
            .vs-item-container {
                display: inline-block;
                vertical-align: middle;
                letter-spacing: 1px;
                height: 100%;
            }
        }
        h3 {
            margin: 22px 0 17px;
            font-size: 14px;
            line-height: 20px;
        }
        p {
            line-height: 22px;
            color: #333333;
            opacity: 1;
        }
    }
    // pc
    @media screen and (min-width: 960px) {
        display: inline-block;
        vertical-align: top;
        width: 400px;
        height: 100%;
        padding-top: 52px;
        padding-left: 56px;
        background-color: #2AA4E4;
        .vs-item {
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            .vs-item-label {
                width: 45px;
                white-space: normal;
                text-align: justify;
                display: inline-block;
                vertical-align: middle;
                height: 100%;
                &::after {
                    content: "";
                    width: 100%;
                    display: inline-block;
                }
            }
            .vs-item-container {
                display: inline-block;
                vertical-align: middle;
                letter-spacing: 1px;
                height: 100%;
            }
        }
        h3 {
            margin-bottom: 32px;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
        }
        p {
            line-height: 40px;
            color: #FFFFFF;
            letter-spacing: 1px;
            opacity: 1;
        }
    }
}
</style>
