<template>
    <div class="info-bar">
        <!-- <i class="iconfont" :class="[icon, activeClass]"></i> -->
        <div class="info-container" @click.stop="onClickBar">
            <a v-if="type === 'qq'" :href="'tencent://message/?uin=' + info + '&Site=Sambow&Menu=yes'">
                <i class="iconfont icon-qq"></i>
                <span class="text">{{ info }}</span>
            </a>
            <a v-else-if="type === 'email'" :href="'mailto:' + info + '@email.com'">
                <i class="iconfont icon-email"></i>
                <span class="text">{{ info }}</span>
            </a>
            <span v-else-if="type === 'wechat'" style="cursor: pointer">
                <i class="iconfont icon-wechat"></i>
                <span class="text text-pointer">{{ info }}</span>
            </span>
            <span v-else>
                <i class="iconfont icon-position"></i>
                <span class="text">{{ info }}</span>
            </span>
        </div>

        <div v-if="type === 'wechat'" v-show="showQrCode" class="info-qr" @click.stop="stop">
            <img src="../assets/img/QRCode.png" alt="">
            <p>扫一扫，加我微信</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoBar',
    props: {
        type: { // info email wechat qq
            type: String,
            default: 'info'
        },
        icon: {
            type: String,
            default: ''
        },
        info: {
            type: String,
            default: '联系方式'
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    mounted () {
        if (this.type === 'wechat') {
            document.body.addEventListener('click', this.onHide, false)
        }
    },
    beforeDestroy () {
        document.body.removeEventListener('click', this.onHide)
    },
    data () {
        return {
            showQrCode: false
        }
    },
    methods: {
        onClickBar () {
            if (this.type === 'wechat') {
                this.showQrCode = !this.showQrCode
            }
        },
        onHide () {
            this.showQrCode = false
        },
        // 屏蔽点击操作
        stop () {}
    }
}
</script>

<style lang="scss" scoped>
$info-active-color: #E4392A;
$text-default-color: #798490;
$default-dark-color: #333333;
.info-bar {
    position: relative;
    z-index: 999;
    .iconfont {
        vertical-align: middle;
        font-size: 20px;
        color: $default-dark-color;
    }
    a {
        text-decoration: none;
        color: $text-default-color;
    }
    .text {
        margin-left: 5px;
        vertical-align: middle;
        font-size: 12px;
        font-family: Roboto, Source Han Sans SC;
        font-weight: bold;
        line-height: 17px;
        color: $text-default-color;
    }
    .text-pointer {
        cursor: pointer;
    }
    .info-container {
        white-space: nowrap;
        cursor: pointer;
        &:hover {
            .iconfont {
                color: $info-active-color;
            }
            .text {
                color: $default-dark-color;
            }
        }
    }
}
.info-qr {
    position: absolute;
    z-index: 1;
    top: 35px;
    left: -72px;
    width: 132px;
    height: 167px;
    background-image: url('../assets/img/dialog.png');
    padding: 18px 10px 12px;
    text-align: center;
    img {
        width: 112px;
        height: 112px;
    }
    p {
        margin: 0;
        font-size: 12px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 17px;
        color: #FFFFFF;
        user-select: none;
    }
}
</style>
