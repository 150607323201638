<template>
    <div class="prod-card">
        <div class="prod-container">
            <slot></slot>
        </div>
        <div class="prod-text">{{ label }}</div>
    </div>
</template>

<script>
export default {
    name: 'ProductionCard',
    props: {
        label: {
            type: String,
            default: '产品名称'
        }
    }
}
</script>

<style lang="scss" scope>
.prod-card {
    width: 100%;
    padding: 8px;
    display: inline-block;
    .prod-container {
        width: 100%;
        height: auto;
        // background: #F9F9F9;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            transition-duration: .3s;
            transition-timing-function: linear;
        }
        transition-duration: .3s;
        transition-timing-function: linear;
        &:hover {
            background-color: #F8F8F8;
            img {
                transform: scale(1.1);
            }
            box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
        }
    }
    .prod-text {
        width: 100%;
        margin-top: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #666666;
        @media screen and (min-width: 960px) {
            font-size: 14px;
            margin-bottom: 64px;
        }
    }
    @media screen and (min-width: 600px){
        width: 50%;
    }
    @media screen and (min-width: 960px){
        width: 25%;
    }
}
</style>
